jQuery(document).ready(function () {
    function mobileMenuToggle() {
        jQuery('nav').toggle("slide", {direction: "left"}, 500);
        jQuery('#close-menu').toggleClass('d-none');
        jQuery('.content').toggle("slide", {direction: "left"}, 500);
    }

    jQuery('.mobile-menu').on('click', mobileMenuToggle);


    jQuery('#close-menu').on('click', mobileMenuToggle)

    jQuery("#cards").mpmansory(
        {
            childrenClass: 'card-bubble', // default is a div
            columnClasses: 'padding', //add classes to items
            breakpoints: {
                xlg: 3,
                lg: 4,
                md: 6,
                sm: 6,
                xs: 12
            },
            distributeBy: {order: false, height: false, attr: 'data-order', attrOrder: 'asc'}, //default distribute by order, options => order: true/false, height: true/false, attr => 'data-order', attrOrder=> 'asc'/'desc'
            onload: function (items) {
                //make somthing with items
            }
        });


    function menuScroll() {
        if (window.innerWidth >= 992) {
            var t = jQuery('header');
            var content = jQuery('.content');
            var pos = 0;

            if (t.height() > content.height())
                content.height(t.height());

            if (t.height() > jQuery(window).height()) {
                var offset = (t.height() - jQuery(window).height()) + 50;
                jQuery(window).on('scroll', function (e) {
                    console.log(1);

                    var cPos = jQuery(document).scrollTop();

                    if (cPos > pos) {
                        console.log(2);

                        if ((offset * -1) !== t.css('top')) {
                            console.log(2.2);

                            t.css({transform: 'translate(0,' + (offset * -1) + 'px)', transition: 'all 700ms'});
                        }

                    } else {
                        console.log(3);

                        var cTop = t.css('top').replace('px', '');

                        if ((cTop !== 0)) {
                            console.log(3.2);

                            t.css({transform: 'translate(0,0px)', transition: 'all 700ms'});
                        }
                    }

                    pos = cPos;

                });
            }
        }
    }

    menuScroll();

    jQuery(window).on('resize', menuScroll);


    var links = document.getElementsByTagName('a');
    for (i = 0; i < links.length; i++) {
        if (links[i].href.indexOf(location.host) == -1) {
            links[i].onclick = function () {
                var site = jQuery(this).find('.button').attr('class').replace('button ', '');

                ga('send', 'event', 'outbound-link', site);


            }
        }
    }

    $('.website__field').hide();
})
