! function(r) {
    r.fn.mpmansory = function(t) {
        var n = r.extend({
            childrenClass: "",
            breakpoints: {
                xlg: 4,
                lg: 4,
                md: 4,
                sm: 6,
                xs: 12
            },
            distributeBy: {
                attr: "data-order",
                attrOrder: "asc",
                order: !1,
                height: !1
            },
            onload: function(r) {
                return !0
            }
        }, t)
        return Array.min = function(r) {
            return Math.min.apply(Math, r)
        }, r.emptyArray = function(r) {
            for (var t = 0; t < r.length; t++) r[t].remove()
            return new Array
        }, r.fn.initialize = function(t, n) {
            for (var e = [], i = 0; t > i; i++) {
                var a = r("<div></div>")
                a.addClass(n), r(this).append(a), e.push(a)
            }
            return e
        }, r.fn.distributeItemsByHeight = function(r, t) {
            for (var n = 0; n < t.length; n++) {
                for (var e = new Array, i = 0; i < r.length; i++) e.push(r[i].height())
                var a = Array.min(e) == Number.POSITIVE_INFINITY || Array.min(e) == Number.NEGATIVE_INFINITY ? 0 : Array.min(e)
                r[e.indexOf(a)].append(t[n])
            }
        }, r.fn.getCurrentColumnSize = function() {
            return window.innerWidth > 1500 ? "xlg" :
window.innerWidth > 1200 ? "lg" :
window.innerWidth > 992 ? "md" : 
window.innerWidth > 720 ? "sm" : 
window.innerWidth > 480 ? "xs" : 
(window.innerWidth > 320, "xs")
        }, r.fn.distributeItemsByOrder = function(r, t) {
            for (var n = 0, e = 0; e < t.length; e++) n == r.length && (n = 0), r[n].append(t[e]), n++
        }, r.fn.orderItemsByAttr = function(t, n) {
            for (var e = new Array, i = 0; i < t.length; i++) e.push(r(t[i]).attr(n.attr))
            "asc" == n.attrOrder ? e.sort(function(r, t) {
                return r - t
            }) : e.sort(function(r, t) {
                return t - r
            })
            for (var a = new Array, s = 0; s < e.length; s++) {
                var o = r.grep(t, function(t) {
                    return r(t).attr(n.attr) == e[s]
                })
                a.push(o)
            }
            return a
        }, r.fn.distributeItemsByAttr = function(t, n, e) {
            for (var i = 0, a = 0, s = 0; s < n.length; s++) i == t.length && (i = 0), n[s].length > 1 ? (a == n[s].length && (a = 0), t[i].append(r(n[s][a])), a++) : t[i].append(r(n[s])), i++
        }, r.fn.apply = function(t, n, e, i) {
            var a = r(this),
                s = (a.getCurrentColumnSize(), n),
                o = "col-xlg-" + t.breakpoints.xlg + " col-lg-" + t.breakpoints.lg + " col-md-" + t.breakpoints.md + " col-sm-" + t.breakpoints.sm + " col-xs-" + t.breakpoints.xs + " " + t.columnClasses
            return e = r(this).initialize(s, o), t.distributeBy.order ? a.distributeItemsByOrder(e, i) : t.distributeBy.height ? a.distributeItemsByHeight(e, i) : t.distributeBy.attr && a.distributeItemsByAttr(e, a.orderItemsByAttr(i, t.distributeBy), t.distributeBy), {
                wrappers: e,
                items: i
            }
        }, this.each(function() {
            var t = r(this),
                e = t.getCurrentColumnSize(),
                i = 12 / n.breakpoints[e],
                a = t.children("" != n.childrenClass ? "." + n.childrenClass : "div"),
                s = new Array,
                o = t.apply(n, i, s, a)
            s = o.wrappers, r(window).on("resize", function(u) {
                t.getCurrentColumnSize() != e && (i = 12 / n.breakpoints[t.getCurrentColumnSize()], s = r.emptyArray(s), o = t.apply(n, i, s, a), s = o.wrappers, e = t.getCurrentColumnSize())
            }), n.hasOwnProperty("onload") && n.onload(a)
        })
    }
}(jQuery)